<template>
  <div id="upload-cv" class="menu menu-box-modal rounded-m bg-theme">
    <div class="menu-title mt-2 mx-2">
      <h4 class="color-highlight">Tambah CV</h4>
      <a href="#" @click="menuClose" class="close-menu"
        ><i class="fa fa-times-circle"></i
      ></a>
    </div>

    <div class="content">
      <div class="divider mt-n2"></div>

      <div class="text-center">
        <i class="fas fa-file-alt font-45 color-highlight mb-3"></i><br />
      </div>

      <input
        type="file"
        ref="fileInput"
        id="file-upload"
        class="shadow-s rounded-s"
        @input="onInput"
      />

      <p class="font-9 color-red-dark font-100 mt-2">
        *Tipe file yang di dukung pdf dan max 8mb
      </p>
      <a
        @click="upload"
        href="#"
        class="
          close-menu
          btn btn-full
          gradient-blue
          font-13
          btn-m
          font-600
          mt-3
          mb-3
          rounded-s
        "
        >Tambah CV</a
      >
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "UploadCVModals",
  emits: ["close", "refresh"],
  props: ["profile"],
  data() {
    return {
      pdfFile: "",
      fileName: "",
      pdfUrl: "",
      photoUrl: "",
    };
  },
  watch: {
    profile() {
      this.photoUrl = axios.defaults.baseURL + this.profile.get_photo;
    },
  },
  mounted() {},
  methods: {
    menuClose() {
      this.$emit("close");
    },
    onInput(event) {
      this.pdfFile = event.target.files[0];
      let fileInput = this.$refs.fileInput;
      let file = fileInput.files;
      if (file && file[0]) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.fileName = e.target.result;
        };
        reader.readAsDataURL(file[0]);
      }
    },
    upload() {
      const fd = new FormData();
      fd.append("resume_upload", this.pdfFile, this.pdfFile.name);
      axios
        .post(
          "/api/v1/job-seeker/profile/photo-profile/",
          fd,
          (uploadEvent) => {
            console.log(
              "progress:" +
                Math.round((uploadEvent.loaded / uploadEvent.total) * 100 + "%")
            );
          }
        )
        .then((response) => {
          console.log(response);
          this.$emit("refresh");
          this.menuClose();
          location.reload();
        })
        .catch((error) => {
          console.log(JSON.stringify(error));
        });
    },
  },
};
</script>
