<template>
  <div class="text-center mt-5" :class="{ 'card-center': cardCenter }">
    <div class="">
      <img src="/images/empty-state.svg" alt="" />
    </div>
    <h5 class="mt-3 line-height-s font-400">
      {{ message1 }} <br />
      {{ message2 }}
    </h5>
  </div>
</template>

<script>
export default {
  name: "EmptyState",
  props: {
    message1: String,
    message2: String,
    cardCenter: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
