<template>
  <div v-if="isLoading" id="preloader">
    <div class="spinner-border color-highlight" role="status"></div>
  </div>
  <div id="page">
    <Header :add_class="['header-auto-show']" />

    <Footer logged />
    <!-- Page Title-->
    <div class="page-title page-title-fixed">
      <a
        @click="$router.go(-1)"
        class="page-title-icon rounded-s ms-3 bg-theme color-theme"
        ><i class="fas fa-chevron-left"></i
      ></a>
      <h1 class="font-18 mx-auto">Upload CV</h1>
      <a href="#" @click="menuOpen('upload-cv')">
        <span
          class="
            btn
            me-3
            font-18 font-500
            btn-xs
            rounded-xs
            shadow-s
            bg-highlight
          "
        >
          <i
            v-if="btnName === 'Tambah'"
            class="fas fa-plus select-all font-14 rounded-sm color-white"
          ></i>
          {{ btnName }}</span
        >
      </a>
    </div>
    <div class="page-title-clear"></div>

    <!-- End Page Title-->

    <!-- Experience List-->
    <div class="page-content mt-4">
      <EmptyState
        v-if="!profile.resume_upload"
        :message1="'Belum ada CV yang ditambahkan'"
        :message2="'silahkan tambahkan CV anda'"
      />

      <PdfViewer
        :profile="profile"
        @delete="deleteCV"
        @changeBtnName="changeBtnName"
      />
    </div>
    <!-- End Experience List-->

    <UploadCVModals @close="menuClose" />

    <div @click="menuClose" class="menu-hider"></div>
  </div>
</template>

<script>
import axios from "axios";
import { init_template, menuClose, menuOpen } from "@/components/menu/index.js";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

import EmptyState from "@/components/EmptyState";
import PdfViewer from "@/components/PdfViewer";
import UploadCVModals from "@/components/job_seeker/UploadCVModals.vue";
export default {
  name: "UploadCv",
  components: {
    Header,
    Footer,
    UploadCVModals,
    PdfViewer,
    EmptyState,
  },
  data() {
    return {
      profile: {},
      isLoading: true,
      errors: [],
      btnName: "Tambah",
    };
  },
  mounted() {
    init_template();
    this.isLoading = false;
    this.getData();
  },
  methods: {
    menuOpen,
    menuClose,
    changeBtnName(name) {
      console.log("btnName");
      this.btnName = name;
    },
    deleteCV() {
      const formData = {
        resume_upload: "",
      };

      var url = "/api/v1/job-seeker/profile/photo-profile/?field=CV";

      axios
        .delete(url)
        .then((response) => {
          console.log(response.data);
          location.reload();
        })
        .catch((error) => {
          if (error.response) {
            console.log(JSON.stringify(error.response.data));
          } else if (error.message) {
            console.log(JSON.stringify(error));
          }
        });
    },

    getData() {
      axios
        .get("/api/v1/job-seeker/profile/")
        .then((response) => {
          this.profile = response.data[0];
        })
        .catch((error) => {
          if (error.response) {
            for (const property in error.response.data) {
              this.errors.push(`${property}: ${error.response.data[property]}`);
            }
            console.log(JSON.stringify(error.response.data));
          } else if (error.message) {
            this.errors.push("Something went wrong please try again");
            console.log(JSON.stringify(error));
          }
        });
    },
  },
};
</script>
