<template>
  <div v-if="pdfUrl" class="card card-style">
    <div class="content mb-0">
      <div class="d-flex mb-4">
        <div class="align-self-center">
          <i
            class="rounded-sm me-3 fas fa-file-alt font-35 color-highlight mb-0"
          ></i
          ><br />
        </div>
        <div class="align-self-center">
          <p class="color-highlight font-11 mb-n2"></p>
          <h2 class="font-15 line-height-s mt-1 mb-1">CV</h2>
        </div>
        <div class="ms-auto ps-3 align-self-center text-center">
          <a
            @click="deleteCV"
            href="#"
            class="
              btn btn-xs
              mb-0
              rounded-xs
              text-uppercase
              shadow-s
              bg-red-dark
            "
            >Hapus</a
          >
        </div>
      </div>
      <!--End card  -->
      <pdf :src="pdfUrl" :page="1"> </pdf>
    </div>
  </div>
</template>

<script>
import pdf from "pdfvuer";
import axios from "axios";
export default {
  name: "PdfViewer",
  props: ["profile"],
  emits: ["changeBtnName"],
  components: {
    pdf,
  },
  data() {
    return {
      pdfUrl: "",
    };
  },
  watch: {
    profile() {
      if (this.profile.resume_upload) {
        this.pdfUrl = axios.defaults.baseURL + this.profile.resume_upload;
        console.log(this.pdfUrl);
        this.$emit("changeBtnName", "Ubah");
      }
    },
  },
  mounted() {
    console.log(this.pdfUrl);
  },
  methods: {
    deleteCV() {
      this.$emit("delete");
    },
  },
};
</script>
